/* Fonts */
/* SFUI */
@font-face {
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/hinted-SFUIText-Medium.0d86a195.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Medium.821fc0a4.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: bold;
  src: url(/static/media/hinted-SFUIText-BoldItalic.5f0b0560.woff2) format('woff2'), url(/static/media/hinted-SFUIText-BoldItalic.9ba75c9f.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/hinted-SFUIText-Regular.c21d8b0f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Regular.a3961e32.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: normal;
  src: url(/static/media/hinted-SFUIText-RegularItalic.46c690ce.woff2) format('woff2'), url(/static/media/hinted-SFUIText-RegularItalic.6a283166.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/hinted-SFUIText-MediumItalic.9f437e6b.woff2) format('woff2'), url(/static/media/hinted-SFUIText-MediumItalic.d810b485.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: bold;
  src: url(/static/media/hinted-SFUIText-Bold.574e639f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Bold.6187952e.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/hinted-SFUIText-Heavy.b65928e3.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Heavy.b3bfba8d.woff) format('woff');
  font-family: 'SF UI Text';
}
/* SFMono */
@font-face {
  src: url(/static/media/SFMonoRegular.ba965fb7.woff) format('woff');
  font-family: 'SF Mono';
}
/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '\25BC';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '\25B2';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  vertical-align: baseline;
  margin: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #cccccc;
  padding: 0;
  height: 1px;
}
input,
select {
  vertical-align: middle;
}
* {
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-rendering: optimizeLegibility !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
html {
  height: 100%;
}
body {
  position: relative;
  background-color: #222629;
  width: 100vw;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: #8a96a0;
  font-weight: 100;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
}
@media print {
  body {
    background-color: transparent;
    color: #1a1d1f;
  }
}
body.ReactModal__Body--open {
  overflow: hidden;
}
::selection {
  background: rgba(138, 150, 160, 0.5);
}
::-moz-selection {
  background: rgba(138, 150, 160, 0.5);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8a96a0;
}
h1,
.h1,
h2,
h3 {
  color: #fff;
  font-weight: bold;
}
@media print {
  h1,
  .h1,
  h2,
  h3 {
    color: #1a1d1f;
  }
}
h1,
.h1 {
  font-size: 40px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-weight: bold;
  font-size: 18px;
}
form > *:not(:last-child) {
  margin-bottom: 1rem;
}
.w {
  color: #fff;
}
h5,
.h5 {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}
h6 {
  margin: 30px 0 10px;
  font-weight: 100;
  font-size: 11px;
  text-transform: uppercase;
}
h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin-top: 15px;
}
ul {
  list-style: none;
}
p {
  line-height: 1.7em;
}
p:not(:last-child) {
  margin-bottom: 15px;
}
a {
  transition: color 0.15s ease;
  color: #fff;
}
a:hover {
  color: #8a96a0;
}
strong {
  color: inherit;
  font-weight: bold;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
code {
  display: block;
  margin: 5px 0;
  border: 2px dashed #f8c81c;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 7px;
  font-family: 'Fira Code', monospace;
}
code.nice {
  border: none;
}
hr {
  margin: 30px 0;
  border: none;
  border-bottom: 1px solid #41494f;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.hidden {
  position: absolute;
  top: -1px;
  left: -1px;
  border: 0;
  width: 0;
  height: 0;
}
@layer {
  /* unstyled button */
  button.unstyled {
    border: none;
    background-color: transparent;
    color: #8a96a0;
    text-align: left;
  }
  button.unstyled:not(:disabled) {
    cursor: pointer;
  }
}
.nospin input[type='number']::-webkit-outer-spin-button,
.nospin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.nospin input[type='number'] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
input[type='number'].nospin::-webkit-outer-spin-button,
input[type='number'].nospin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'].nospin {
  -webkit-appearance: textfield;
  appearance: textfield;
}
.noscroll {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.ReactModal__Overlay {
  -webkit-perspective: 1600px;
  perspective: 1600px;
  opacity: 0;
  z-index: 25;
  transition: opacity 150ms ease-in-out;
}
.ReactModal__Overlay > div {
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  /* Had to disable this so dnd will play nice inside modals :(
		 * https://github.com/atlassian/react-beautiful-dnd#warning-position-fixed
		 */
  /*> div {
			transform: scale(1);
		}*/
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Overlay--before-close > div {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.recharts-text {
  fill: #8a96a0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.recharts-tooltip-cursor,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #41494f;
}
.recharts-cartesian-grid line {
  stroke: #41494f;
}
.recharts-cartesian-axis > .recharts-text {
  fill: #8a96a0;
  text-anchor: middle;
}
.recharts-polar-angle-axis .recharts-text {
  fill: #8a96a0;
}
.rsel__control {
  transition: border-color 0.15s ease, opacity 0.15s ease !important;
  border-color: #41494f !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  min-height: 39px !important;
}
.rsel__control input {
  color: #8a96a0 !important;
}
.rsel__control:hover {
  border-color: #8a96a0 !important;
}
.rsel__control + div {
  position: relative;
  z-index: 2;
}
.rsel__control--is-disabled {
  opacity: 0.5;
}
.rsel__control--is-focused,
.rsel__control--is-focused:hover {
  box-shadow: none !important;
  border-color: #fff !important;
}
.rsel__placeholder {
  color: rgba(138, 150, 160, 0.3) !important;
}
.rsel__indicator:hover {
  color: #8a96a0 !important;
}
.rsel__indicator-separator {
  background-color: #41494f !important;
}
.rsel__single-value {
  padding: 1px 0;
  color: #fff !important;
}
.rsel__menu {
  position: absolute !important;
  z-index: 25;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #41494f !important;
  background-color: #1a1d1f !important;
}
.rsel__option {
  transition: background-color 0.15s ease;
}
.rsel__option:hover,
.rsel__option--is-focused {
  background-color: rgba(65, 73, 79, 0.25) !important;
}
.rsel__option--is-selected,
.rsel__option--is-selected:hover {
  background-color: #41494f !important;
  color: #fff !important;
}
.rsel__option--is-selected *,
.rsel__option--is-selected:hover * {
  color: #fff !important;
}
.rsel__multi-value__remove:hover {
  background-color: #ee1737 !important;
}
.rsel__multi-value__remove:hover path {
  fill: #fff;
}
.rsel__multi-value {
  padding: 4px;
  font-size: 16px;
}
.rsel__value-container--is-multi {
  padding: 2px !important;
}
.rsel__value-container--is-multi input {
  margin-left: 8px;
}
.rsel__value-container--is-multi .rsel__placeholder {
  margin-left: 8px !important;
}
.rbc-calendar {
  height: 100%;
}
.rbc-calendar .rbc-time-header {
  z-index: 3;
  background-color: #222629;
}
.rbc-calendar .rbc-time-header .rbc-time-header-gutter {
  border-bottom: 1px solid #41494f;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  z-index: 3;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .menu,
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .rbc-timeslot-create {
  display: none;
}
.rbc-calendar .rbc-allday-cell {
  display: none;
}
.rbc-calendar .rbc-current-time-indicator {
  background-color: #f8c81c;
  width: 700%;
}
.rbc-calendar .rbc-timeslot-group {
  min-height: 77px;
}
.rbc-calendar .rbc-timeslot-group .rbc-label {
  display: block;
  background-color: #222629;
  padding: 0 7.5px;
  font-size: 11px;
}
.rbc-calendar .rbc-timeslot-group:not(:first-child) .rbc-label {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.rbc-calendar .rbc-time-header-content {
  border-left: 0;
}
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-header + .rbc-header {
  border-color: #41494f;
}
.rbc-calendar .rbc-header:first-child {
  border-left: 1px solid transparent;
}
.rbc-calendar .rbc-header + .rbc-header {
  border-image: linear-gradient(to top, #41494f, #222629) 1 1%;
}
.rbc-calendar .rbc-header {
  padding: 1rem;
  text-align: left;
}
.rbc-calendar .rbc-header button {
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
.rbc-calendar .rbc-header button strong {
  display: block;
  margin-top: 0.2rem;
  color: white;
  font-size: 1.5rem;
  text-align: left;
}
.rbc-calendar .rbc-time-header.rbc-overflowing {
  border-right: 0;
}
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-events-container,
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-timeslot-group {
  border-left: 0;
}
.rbc-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.rbc-calendar .rbc-time-slot {
  background-color: #1a1d1f !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  background-color: #222629 !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  border-right: none;
  border-bottom: 1px #41494f solid;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  border: none;
}
.rbc-calendar .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}
.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 0;
}
.rbc-calendar .rbc-time-view {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-view-resources {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-content {
  border-top: 0;
  overflow-x: hidden;
}
.rbc-calendar .rbc-timeslot-group {
  position: relative;
  border-bottom: 1px solid rgba(65, 73, 79, 0.3);
}
.rbc-calendar .rbc-time-content > * + * > * {
  border-left: 1px solid #41494f;
}
.rbc-calendar .rbc-today {
  background-color: rgba(248, 200, 28, 0.05);
}
.rbc-calendar .rbc-today.rbc-header * {
  color: #f8c81c;
}
.rbc-calendar .rbc-day-slot .rbc-event {
  z-index: 2;
  border: 0;
  border-left: 4px solid;
  overflow: visible;
}
.rbc-calendar .rbc-event-wrapper .rbc-event,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview {
  gap: 0.2rem;
  padding: 0.3rem 0.5rem;
  overflow: hidden;
}
.rbc-calendar .rbc-event-wrapper .rbc-event strong,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview strong {
  display: block;
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event *,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview * {
  font-size: 12px;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-label {
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg path {
  fill: currentColor;
}
.rbc-calendar.rbc-addons-dnd-is-dragging .rbc-event-wrapper.dragging .rbc-event {
  opacity: 0 !important;
}
.rbc-calendar .rbc-event-wrapper.workout .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.workout .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f8c81c;
  background-color: rgba(248, 200, 28, 0.3);
  color: #f8c81c;
}
.rbc-calendar .rbc-event-wrapper.competition .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.competition .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #48c118;
  background-color: rgba(72, 193, 24, 0.3);
  color: #48c118;
}
.rbc-calendar .rbc-event-wrapper.training .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.training .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #2196f3;
  background-color: rgba(33, 150, 243, 0.3);
  color: #2196f3;
}
.rbc-calendar .rbc-event-wrapper.meal .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meal .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ff7f2f;
  background-color: rgba(255, 127, 47, 0.3);
  color: #ff7f2f;
}
.rbc-calendar .rbc-event-wrapper.meeting .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f48ab9;
  background-color: rgba(244, 138, 185, 0.3);
  color: #f48ab9;
}
.rbc-calendar .rbc-event-wrapper.meeting2 .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting2 .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ab8af4;
  background-color: rgba(171, 138, 244, 0.3);
  color: #ab8af4;
}
.rbc-calendar .rbc-event-wrapper.travel .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.travel .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #8a96a0;
  background-color: rgba(138, 150, 160, 0.3);
  color: #8a96a0;
}
.ag-theme-alpine {
  --ag-background-color: #1a1d1f;
  --ag-foreground-color: #8a96a0;
  /* Darker text */
  --ag-header-background-color: #1a1d1f;
  /* Custom header background */
  --ag-row-hover-color: #41494f;
  /* Custom row hover effect */
  --ag-font-size: 15px;
  /* Change font size */
  --ag-row-height: 48px;
  /* Increase row height */
  --ag-border-color: #41494f;
  --ag-secondary-border-color: #41494f;
  --ag-input-focus-border-color: #41494f;
  --ag-header-column-resize-handle-color: #f8c81c;
  --ag-alpine-active-color: #f8c81c;
  --ag-range-selection-border-color: #f8c81c;
  --ag-input-focus-border-color: #f8c81c;
  --ag-selected-row-background-color: #222629;
  --ag-value-change-value-highlight-background-color: #41494f;
  --ag-control-panel-background-color: #1a1d1f;
  --ag-advanced-filter-join-pill-color: #1a1d1f;
}
.ag-theme-alpine .ag-header {
  border-bottom: 2px solid #41494f;
  /* Set border color and thickness for header */
}
.ag-theme-alpine .ag-ltr .ag-filter-toolpanel-header {
  background-color: #1a1d1f;
}
.ag-theme-alpine .ag-row:nth-child(odd) {
  border-bottom: 1px solid #41494f;
  /* Set row border color */
  background-color: #1a1d1f;
  color: #fff;
}
.ag-theme-alpine .ag-row:nth-child(odd):hover {
  background-color: #222629;
}
.ag-theme-alpine .ag-row:nth-child(even) {
  border-bottom: 1px solid #41494f;
  /* Set row border color */
  background-color: #1a1d1f;
  color: #fff;
}
.ag-theme-alpine .ag-row:nth-child(even):hover {
  background-color: #222629;
}
.ag-theme-alpine .ag-header {
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: capitalize;
}
.ag-theme-alpine input[class^='ag-'][type='text']:focus {
  box-shadow: 0 0 2px 0.1rem rgba(170, 170, 170, 0.4) !important;
}
.ag-rich-select-value {
  background-color: #1a1d1f;
  color: #ffffff;
}
.ag-virtual-list-item,
.ag-rich-select-virtual-list-item {
  background-color: #1a1d1f !important;
  /* Set background color to black */
  color: white !important;
  /* Optional: Set text color to white for better contrast */
}
.ag-theme-alpine .ag-paging-panel .ag-paging-button {
  color: #f8c81c !important;
}
.ag-theme-alpine .ag-input-field-input ag-text-field-input {
  box-shadow: 0 0 2px 0.1rem #f8c81c;
}
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: #f8c81c !important;
}
.ag-theme-alpine .ag-column-select-header {
  border-bottom: #222629;
}
.ag-theme-alpine .ag-popup {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-menu-header {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-menu {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-tab {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-tab:hover {
  color: #f8c81c;
}
.ag-theme-alpine .ag-popup .ag-tab-selected {
  border-bottom-color: #f8c81c;
  color: #f8c81c;
}
.podium-cell {
  position: relative;
  background: linear-gradient(to right, rgba(0, 255, 0, 0.2) 0%, transparent 50%);
  overflow: hidden;
  /* Ensure the pseudo-element doesn't overflow */
  color: #48c118;
}
.podium-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #48c118 0%, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
.top-8-cell {
  background: linear-gradient(to right, rgba(255, 255, 0, 0.2) 0%, transparent 50%);
  color: #f8c81c;
}
.top-8-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #f8c81c, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
.top-16-cell {
  background: linear-gradient(to right, rgba(255, 102, 0, 0.2) 0%, transparent 50%);
  color: #f86a1c;
}
.top-16-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #f86a1c 0%, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
@media print {
  .rsel__indicator-separator,
  .rsel__dropdown-indicator {
    display: none !important;
  }
}
.rsel__dropdown-indicator {
  display: none !important;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.App_container__2xkqL {
  padding: 0 30px 30px;
}
.App_containerAll__l9MfY {
  padding: 30px;
}
.App_containerTb__2i1L8 {
  padding: 30px 0;
}
.App_containerLr__2mT4Y {
  margin: 0 30px;
}
.App_containerRbl__1c2SE {
  margin: 0 30px 30px;
}
.App_textCenter__15HXN {
  text-align: center;
}
.App_flex__1u7z8 {
  display: flex;
}
.App_well__2LLfw {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.App_well__2LLfw:not(:last-child) {
  margin-bottom: 10px;
}
.App_white__NWCLg {
  color: #fff;
}
.App_error__2OxV0 {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.App_disabled__3tfIA {
  opacity: 0.5;
  pointer-events: none;
}
.App_graph__pFKL3 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.App_graphHeader__1fgk-,
.App_graph-header__3rMuB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.App_buttonGroup__32rJk {
  display: flex;
}
.App_buttonGroup__32rJk button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.App_center__3U3hD {
  justify-content: center;
  align-items: center;
}
.App_bodyMapDot__3VLq0 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.App_good__30665,
.App_success__17qJM {
  color: #26d87c;
}
.App_warning__8uYNU {
  color: #f8c81c;
}
.App_bad__ZEjgR,
.App_danger__TYBtz {
  color: #ee1737;
}
.App_niceSwitch__IhvdE {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.App_niceSwitch__IhvdE + .App_niceSwitch__IhvdE {
  margin-top: calc(-1rem + 8px);
}
.App_switch__2TfB6 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.App_switch__2TfB6 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.App_switch__2TfB6 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.App_switch__2TfB6 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.App_switch__2TfB6 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.App_tableSortButton__3aXVw) {
  padding: 0 !important;
}
th:has(.App_tableSortButton__3aXVw) .App_tableSortButton__3aXVw {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.App_tableSortButton__3aXVw) .App_tableSortButton__3aXVw::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.App_tableSortButton__3aXVw) .App_tableSortButton__3aXVw.App_descending__3hYlK::after {
  content: '\25BC';
}
th:has(.App_tableSortButton__3aXVw) .App_tableSortButton__3aXVw.App_ascending__2kfog::after {
  content: '\25B2';
}
table.App_tableSort__daQDT th:has(button) {
  padding: 0 !important;
}
table.App_tableSort__daQDT th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .App_printHidden__B8eog {
    display: none !important;
  }
}
.App_pageHead__3Djr8 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.App_pageHead__3Djr8::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.App_noArrows__3XV0Q::-webkit-outer-spin-button,
input.App_noArrows__3XV0Q::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.App_noArrows__3XV0Q[type='number'] {
  -moz-appearance: textfield;
}
div.App_light__1ZrqS {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes App_spin__1WB8E {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App_spinCentre__vCpL2 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
body.fixedHeight,
body.fixedHeight #root,
body.fixedHeight #root main {
  height: 100%;
  overflow: hidden;
  /* safari x scroll */
}
body.smallSidebar .App_sidebar__3HCbF {
  -webkit-transform: translateX(calc(-100% +  80px));
  transform: translateX(calc(-100% +  80px));
}
body.smallSidebar .App_nav__3hywz {
  -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
  transform: translateX(calc(100% -  80px - 0.5rem));
}
body.smallSidebar .App_nav__3hywz a span {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
body.smallSidebar .App_version__3ybvE {
  display: none;
}
body.smallSidebar .App_logout__6QhwQ {
  -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
  transform: translateX(calc(100% - 80px - 30px / 2));
}
body.smallSidebar .App_logout__6QhwQ span {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
body.smallSidebar .App_logout__6QhwQ svg {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
body.smallSidebar .App_body__im2PJ {
  margin-left: 80px;
}
.App_sidebar__3HCbF {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 20;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  background-color: #1a1d1f;
  width: 240px;
  height: 100vh;
  overflow: auto;
}
@media (max-width: 1367px) {
  .App_sidebar__3HCbF {
    -webkit-transform: translateX(calc(-100% +  80px));
    transform: translateX(calc(-100% +  80px));
  }
}
@media print {
  .App_sidebar__3HCbF {
    display: none;
    transition: none;
  }
}
.App_sidebar__3HCbF.App_super__3KQnt {
  border-right-color: #8a96a0;
  background-color: #41494f;
}
.App_logo__3C24V {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: calc(100px + 30px*2);
}
.App_logo__3C24V img {
  max-width: 80px;
  height: auto;
}
.App_nav__3hywz {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 0 1rem 1rem;
}
@media (max-width: 1367px) {
  .App_nav__3hywz {
    -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
    transform: translateX(calc(100% -  80px - 0.5rem));
  }
}
.App_nav__3hywz a {
  display: flex;
  align-items: center;
  transition: color 0.15s ease, background-color 0.15s ease;
  margin: 0 0 10px;
  border-radius: 20px;
  padding: 1.1rem;
  width: 100%;
  height: 32px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}
.App_nav__3hywz a svg {
  vertical-align: middle;
  margin-right: 15px;
  width: 20px;
}
.App_nav__3hywz a svg line,
.App_nav__3hywz a svg path,
.App_nav__3hywz a svg polyline,
.App_nav__3hywz a svg polygon,
.App_nav__3hywz a svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
.App_nav__3hywz a span {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  color: inherit;
}
@media (max-width: 1367px) {
  .App_nav__3hywz a span {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.App_nav__3hywz a i {
  display: inline-block;
  border-radius: 50%;
  background-color: #f8c81c;
  width: 8px;
  height: 8px;
}
.App_nav__3hywz a.App_active__3RTW0,
.App_nav__3hywz a:hover {
  color: #fff;
}
.App_nav__3hywz a.App_active__3RTW0 svg line,
.App_nav__3hywz a:hover svg line,
.App_nav__3hywz a.App_active__3RTW0 svg path,
.App_nav__3hywz a:hover svg path,
.App_nav__3hywz a.App_active__3RTW0 svg polyline,
.App_nav__3hywz a:hover svg polyline,
.App_nav__3hywz a.App_active__3RTW0 svg polygon,
.App_nav__3hywz a:hover svg polygon,
.App_nav__3hywz a.App_active__3RTW0 svg rect,
.App_nav__3hywz a:hover svg rect {
  stroke: #fff;
}
.App_nav__3hywz a.App_active__3RTW0 {
  background-color: #485056;
}
.smallSidebar .App_collapseMenuButton__2FEX5 {
  align-self: flex-end;
  width: 50px;
}
.smallSidebar .App_collapseMenuButton__2FEX5 svg {
  rotate: 180deg;
}
.App_collapseMenuButton__2FEX5 {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  transition: color 0.15s, width 0.5s;
  cursor: pointer;
  margin: 1rem;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 1367px) {
  .App_collapseMenuButton__2FEX5 {
    display: none;
  }
}
.App_collapseMenuButton__2FEX5 .App_text__3GEhd {
  overflow: hidden;
}
.App_collapseMenuButton__2FEX5 svg {
  min-width: 18px;
}
.App_collapseMenuButton__2FEX5 svg g {
  transition: stroke 0.15s;
}
.App_collapseMenuButton__2FEX5:hover {
  color: #a6afb7;
}
.App_collapseMenuButton__2FEX5:hover svg g {
  stroke: #a6afb7;
}
.App_version__3ybvE {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 15px 30px;
  color: rgba(138, 150, 160, 0.5);
  font-size: 11px;
}
.App_version__3ybvE button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0 0 0 10px;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  color: red;
}
@media (max-width: 1367px) {
  .App_version__3ybvE {
    display: none;
  }
}
.App_logout__6QhwQ {
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-top: 1px solid #41494f;
  background: none;
  padding: 30px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
}
@media (max-width: 1367px) {
  .App_logout__6QhwQ {
    -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
    transform: translateX(calc(100% - 80px - 30px / 2));
  }
}
.App_super__3KQnt .App_logout__6QhwQ {
  border-top-color: #485056;
}
.App_logout__6QhwQ span {
  display: inline-block;
  transition: color 0.15s ease, -webkit-transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_logout__6QhwQ span {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
.App_logout__6QhwQ svg {
  vertical-align: middle;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  margin-right: 15px;
  width: 20px;
}
.App_logout__6QhwQ svg line,
.App_logout__6QhwQ svg path,
.App_logout__6QhwQ svg polyline,
.App_logout__6QhwQ svg polygon,
.App_logout__6QhwQ svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
@media (max-width: 1367px) {
  .App_logout__6QhwQ svg {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
.App_logout__6QhwQ.App_active__3RTW0 span,
.App_logout__6QhwQ:hover span {
  color: #fff;
}
.App_logout__6QhwQ.App_active__3RTW0 svg line,
.App_logout__6QhwQ:hover svg line,
.App_logout__6QhwQ.App_active__3RTW0 svg path,
.App_logout__6QhwQ:hover svg path,
.App_logout__6QhwQ.App_active__3RTW0 svg polyline,
.App_logout__6QhwQ:hover svg polyline,
.App_logout__6QhwQ.App_active__3RTW0 svg polygon,
.App_logout__6QhwQ:hover svg polygon,
.App_logout__6QhwQ.App_active__3RTW0 svg rect,
.App_logout__6QhwQ:hover svg rect {
  stroke: #fff;
}
.App_body__im2PJ {
  display: flex;
  flex-direction: column;
  transition: margin-left 0.5s ease;
  margin-left: 240px;
  min-height: 100vh;
}
@media print {
  .App_body__im2PJ {
    transition: none;
    margin-left: 0 !important;
  }
}
@media (max-width: 1367px) {
  .App_body__im2PJ {
    margin-left: 80px;
  }
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Notifications_container__3sS7p {
  padding: 0 30px 30px;
}
.Notifications_containerAll__32mMN {
  padding: 30px;
}
.Notifications_containerTb__3C0cz {
  padding: 30px 0;
}
.Notifications_containerLr__22szJ {
  margin: 0 30px;
}
.Notifications_containerRbl__22L1H {
  margin: 0 30px 30px;
}
.Notifications_textCenter__3bldz {
  text-align: center;
}
.Notifications_flex__1QQOy {
  display: flex;
}
.Notifications_well__2Rw9j {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Notifications_well__2Rw9j:not(:last-child) {
  margin-bottom: 10px;
}
.Notifications_white__1fC9U {
  color: #fff;
}
.Notifications_error__q3yqw {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Notifications_disabled__JIHq1 {
  opacity: 0.5;
  pointer-events: none;
}
.Notifications_graph__1dKMX {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Notifications_graphHeader__2WcRj,
.Notifications_graph-header__3LARr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Notifications_buttonGroup___MTYQ {
  display: flex;
}
.Notifications_buttonGroup___MTYQ button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Notifications_center__2GPGB {
  justify-content: center;
  align-items: center;
}
.Notifications_bodyMapDot__2pVOd {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Notifications_good__2VdtS,
.Notifications_success__142UW {
  color: #26d87c;
}
.Notifications_warning__k4-Ij {
  color: #f8c81c;
}
.Notifications_bad__1YCMu,
.Notifications_danger__AEbVE {
  color: #ee1737;
}
.Notifications_niceSwitch__2sHNi {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Notifications_niceSwitch__2sHNi + .Notifications_niceSwitch__2sHNi {
  margin-top: calc(-1rem + 8px);
}
.Notifications_switch___wqcp {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Notifications_switch___wqcp label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Notifications_switch___wqcp label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Notifications_switch___wqcp label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Notifications_switch___wqcp label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Notifications_tableSortButton__O5_t9) {
  padding: 0 !important;
}
th:has(.Notifications_tableSortButton__O5_t9) .Notifications_tableSortButton__O5_t9 {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Notifications_tableSortButton__O5_t9) .Notifications_tableSortButton__O5_t9::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Notifications_tableSortButton__O5_t9) .Notifications_tableSortButton__O5_t9.Notifications_descending__3LG3h::after {
  content: '\25BC';
}
th:has(.Notifications_tableSortButton__O5_t9) .Notifications_tableSortButton__O5_t9.Notifications_ascending__23Z0V::after {
  content: '\25B2';
}
table.Notifications_tableSort__3dVvo th:has(button) {
  padding: 0 !important;
}
table.Notifications_tableSort__3dVvo th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Notifications_printHidden__1ULnN {
    display: none !important;
  }
}
.Notifications_pageHead__2ffvj {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Notifications_pageHead__2ffvj::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Notifications_noArrows__2VA8K::-webkit-outer-spin-button,
input.Notifications_noArrows__2VA8K::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Notifications_noArrows__2VA8K[type='number'] {
  -moz-appearance: textfield;
}
div.Notifications_light__3528S {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Notifications_spin__Ml3I_ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Notifications_spinCentre__38Auo {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Notifications_list__bROZD {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  width: 100%;
  max-width: 380px;
  pointer-events: none;
  list-style: none;
}
.Notifications_li__95hAL {
  display: block;
  padding: 10px 10px 0;
  width: 100%;
  overflow: visible;
}
.Notifications_notification__1cqxx {
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  pointer-events: all;
  color: #8a96a0;
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: none;
}
.Notifications_notification__1cqxx > div {
  flex-shrink: 9999;
  padding-left: 20px;
}
.Notifications_notification__1cqxx strong {
  display: block;
  margin-bottom: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Auth_container__8G4VG {
  padding: 0 30px 30px;
}
.Auth_containerAll__2LK7V {
  padding: 30px;
}
.Auth_containerTb__1eKXC {
  padding: 30px 0;
}
.Auth_containerLr__2SmJ9 {
  margin: 0 30px;
}
.Auth_containerRbl__2fkZL {
  margin: 0 30px 30px;
}
.Auth_textCenter__8QD2a {
  text-align: center;
}
.Auth_flex__gkNKs {
  display: flex;
}
.Auth_well__3Afpd {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Auth_well__3Afpd:not(:last-child) {
  margin-bottom: 10px;
}
.Auth_white__3HZHN {
  color: #fff;
}
.Auth_error__3VQUe {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Auth_disabled__2RVGt {
  opacity: 0.5;
  pointer-events: none;
}
.Auth_graph__r_OZ7 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Auth_graphHeader__1faNK,
.Auth_graph-header__2JhWZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Auth_buttonGroup__2wiH7 {
  display: flex;
}
.Auth_buttonGroup__2wiH7 button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Auth_center__2dhZo {
  justify-content: center;
  align-items: center;
}
.Auth_bodyMapDot__1gPef {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Auth_good__2Ce57,
.Auth_success__1Br09 {
  color: #26d87c;
}
.Auth_warning__3xZ1p {
  color: #f8c81c;
}
.Auth_bad__1746E,
.Auth_danger__35vx7 {
  color: #ee1737;
}
.Auth_niceSwitch__2ZlEA {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Auth_niceSwitch__2ZlEA + .Auth_niceSwitch__2ZlEA {
  margin-top: calc(-1rem + 8px);
}
.Auth_switch__17EC0 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Auth_switch__17EC0 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Auth_switch__17EC0 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Auth_switch__17EC0 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Auth_switch__17EC0 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Auth_tableSortButton__1FgKs) {
  padding: 0 !important;
}
th:has(.Auth_tableSortButton__1FgKs) .Auth_tableSortButton__1FgKs {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Auth_tableSortButton__1FgKs) .Auth_tableSortButton__1FgKs::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Auth_tableSortButton__1FgKs) .Auth_tableSortButton__1FgKs.Auth_descending__3BHG0::after {
  content: '\25BC';
}
th:has(.Auth_tableSortButton__1FgKs) .Auth_tableSortButton__1FgKs.Auth_ascending__2gi2c::after {
  content: '\25B2';
}
table.Auth_tableSort__bdObI th:has(button) {
  padding: 0 !important;
}
table.Auth_tableSort__bdObI th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Auth_printHidden__1Epxo {
    display: none !important;
  }
}
.Auth_pageHead__2rwdo {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Auth_pageHead__2rwdo::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Auth_noArrows__3h4ze::-webkit-outer-spin-button,
input.Auth_noArrows__3h4ze::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Auth_noArrows__3h4ze[type='number'] {
  -moz-appearance: textfield;
}
div.Auth_light__nG71H {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Auth_spin__1MTj5 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Auth_spinCentre__2wnPv {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Auth_wrap__2col0 {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .Auth_wrap__2col0 {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .Auth_wrap__2col0 h3 {
    font-size: 30px;
    line-height: 1.25em;
  }
}
.Auth_form__YDHMw {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  z-index: 2;
  transition: -webkit-transform 0.75s ease;
  transition: transform 0.75s ease;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease;
  background-color: #222629;
  padding: 30px;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .Auth_form__YDHMw {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100vw;
    height: auto;
    min-height: calc(100vh - 200px);
  }
}
.Auth_validSlug__2nKt7 .Auth_form__YDHMw {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.Auth_form__YDHMw h3 {
  margin-bottom: 30px;
  width: 100%;
}
.Auth_form__YDHMw h3 + p {
  margin-top: -15px;
  margin-bottom: 30px;
}
.Auth_form__YDHMw form {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
}
@media (max-width: 1000px) {
  .Auth_form__YDHMw form {
    align-items: flex-start;
    margin-top: -67.5px;
  }
}
.Auth_form__YDHMw footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 12px;
}
.Auth_form__YDHMw footer .Auth_branding__2IG-R {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: lighter;
  font-size: 16px;
}
.Auth_form__YDHMw footer .Auth_logo__PbCvb svg {
  width: 24px;
  /* Scale down the SVG width */
  height: 24px;
  /* Scale down the SVG height */
}
.Auth_form__YDHMw footer span {
  display: inline-block;
  padding: 0 10px;
}
.Auth_form__YDHMw footer a {
  color: #8a96a0;
  text-decoration: none;
}
.Auth_form__YDHMw footer a:hover {
  text-decoration: underline;
}
.Auth_form-wrap__KpWI6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.Auth_form-wrap__KpWI6 img {
  object-fit: contain;
}
.Auth_form-wrap__KpWI6 > *:not(:last-child, svg) {
  margin-bottom: 1.875rem;
}
.Auth_form-wrap__KpWI6 > div {
  width: 100%;
}
.Auth_form-wrap__KpWI6 > svg {
  margin-bottom: 60px;
}
.Auth_form-wrap__KpWI6 label {
  width: 100%;
}
.Auth_form-wrap__KpWI6 button {
  margin: 0 0 30px;
  width: 100%;
}
.Auth_notice__29HJu {
  margin: 0 0 30px;
  width: 100%;
}
.Auth_img__1iAPx {
  position: relative;
  z-index: 1;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .Auth_img__1iAPx {
    width: 100vw;
    height: 200px;
  }
}
.Auth_img__1iAPx img {
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease !important;
  transition: transform 0.75s ease !important;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease !important;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.Auth_validSlug__2nKt7 .Auth_img__1iAPx img {
  -webkit-transform: translateX(-25vw);
  transform: translateX(-25vw);
}
@media (max-width: 1000px) {
  .Auth_img__1iAPx img {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
.Auth_fade__3QAgO {
  opacity: 0.3 !important;
}
s .Auth_input__1vo29 {
  margin-bottom: 15px;
  border: 1px solid #41494f;
  border-radius: 4px;
  padding: 0 0 0 14px;
  width: 100%;
}
.Auth_why__1FyMd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Auth_why__1FyMd button {
  opacity: 0.25;
  margin: 0;
  width: auto;
}
.Auth_why__1FyMd button span {
  text-decoration: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeed_container__3ddvJ {
  padding: 0 30px 30px;
}
.NewsFeed_containerAll__2zbyl {
  padding: 30px;
}
.NewsFeed_containerTb__3AaO7 {
  padding: 30px 0;
}
.NewsFeed_containerLr__2XpTS {
  margin: 0 30px;
}
.NewsFeed_containerRbl__1WmlW {
  margin: 0 30px 30px;
}
.NewsFeed_textCenter__2XOLC {
  text-align: center;
}
.NewsFeed_flex__2ZgdI {
  display: flex;
}
.NewsFeed_well__2qXgZ {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NewsFeed_well__2qXgZ:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeed_white__1AG_X {
  color: #fff;
}
.NewsFeed_error__3soGP {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NewsFeed_disabled__1Y01y {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeed_graph__Nwbvh {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NewsFeed_graphHeader__3KgQA,
.NewsFeed_graph-header__eJld6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NewsFeed_buttonGroup__19l2H {
  display: flex;
}
.NewsFeed_buttonGroup__19l2H button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeed_center__J6WfR {
  justify-content: center;
  align-items: center;
}
.NewsFeed_bodyMapDot__2_yhI {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NewsFeed_good__18Uaa,
.NewsFeed_success__3jSii {
  color: #26d87c;
}
.NewsFeed_warning__HlZ2m {
  color: #f8c81c;
}
.NewsFeed_bad__MemyZ,
.NewsFeed_danger__1_3sy {
  color: #ee1737;
}
.NewsFeed_niceSwitch__34lt3 {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NewsFeed_niceSwitch__34lt3 + .NewsFeed_niceSwitch__34lt3 {
  margin-top: calc(-1rem + 8px);
}
.NewsFeed_switch__3PjGH {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NewsFeed_switch__3PjGH label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NewsFeed_switch__3PjGH label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NewsFeed_switch__3PjGH label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NewsFeed_switch__3PjGH label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NewsFeed_tableSortButton__259km) {
  padding: 0 !important;
}
th:has(.NewsFeed_tableSortButton__259km) .NewsFeed_tableSortButton__259km {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NewsFeed_tableSortButton__259km) .NewsFeed_tableSortButton__259km::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NewsFeed_tableSortButton__259km) .NewsFeed_tableSortButton__259km.NewsFeed_descending__cDw7K::after {
  content: '\25BC';
}
th:has(.NewsFeed_tableSortButton__259km) .NewsFeed_tableSortButton__259km.NewsFeed_ascending__93I49::after {
  content: '\25B2';
}
table.NewsFeed_tableSort__37NCe th:has(button) {
  padding: 0 !important;
}
table.NewsFeed_tableSort__37NCe th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NewsFeed_printHidden__3CjA6 {
    display: none !important;
  }
}
.NewsFeed_pageHead__3DvCK {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NewsFeed_pageHead__3DvCK::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NewsFeed_noArrows__3Kx_-::-webkit-outer-spin-button,
input.NewsFeed_noArrows__3Kx_-::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NewsFeed_noArrows__3Kx_-[type='number'] {
  -moz-appearance: textfield;
}
div.NewsFeed_light__zeNx8 {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeed_spin__3lgZj {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeed_spinCentre__2k-Oj {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.smallSidebar .NewsFeed_feed-btn__1sUii {
  -webkit-transform: translate3d(65px, -50%, 0);
  transform: translate3d(65px, -50%, 0);
}
.NewsFeed_feed-btn__1sUii {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(68px, -50%, 0);
  transform: translate3d(68px, -50%, 0);
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
}
.NewsFeed_feed-btn__1sUii svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .NewsFeed_feed-btn__1sUii {
    -webkit-transform: translate3d(65px, -50%, 0);
    transform: translate3d(65px, -50%, 0);
  }
}
.NewsFeed_overlay__Kx-ji {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 240px;
  opacity: 0;
  z-index: 25;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.2s ease;
  background-color: rgba(34, 38, 41, 0.8);
  overflow: hidden;
}
@media (max-width: 1367px) {
  .NewsFeed_overlay__Kx-ji {
    left: 80px;
  }
}
.NewsFeed_overlayOpen__26sae {
  opacity: 1;
}
.NewsFeed_overlayClose__291Y6 {
  opacity: 0;
  transition-delay: 0.1s;
}
.NewsFeed_modal__3WtNJ {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  inset: unset;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-right: 1px solid #41494f;
  border-radius: 0;
  background-color: #222629;
  padding: 30px;
  width: 100%;
  max-width: 650px;
  height: 100vh;
  overflow: auto;
}
.NewsFeed_modal__3WtNJ h3 {
  margin-bottom: 30px;
}
.NewsFeed_modalOpen__nU3sG {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.NewsFeed_modalClose__27hcW {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeedItem_container__1yKXx {
  padding: 0 30px 30px;
}
.NewsFeedItem_containerAll__4WaeQ {
  padding: 30px;
}
.NewsFeedItem_containerTb__3tnuJ {
  padding: 30px 0;
}
.NewsFeedItem_containerLr__1zWF_ {
  margin: 0 30px;
}
.NewsFeedItem_containerRbl__3HzgI {
  margin: 0 30px 30px;
}
.NewsFeedItem_textCenter__17OJC {
  text-align: center;
}
.NewsFeedItem_flex__1IYp6 {
  display: flex;
}
.NewsFeedItem_well__1FYa9 {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NewsFeedItem_well__1FYa9:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeedItem_white__3zjMI {
  color: #fff;
}
.NewsFeedItem_error__mSRDU {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NewsFeedItem_disabled__Yf-N7 {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeedItem_graph__e_Fr6 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NewsFeedItem_graphHeader__2OUUv,
.NewsFeedItem_graph-header__Gtmqr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NewsFeedItem_buttonGroup__1_pEt {
  display: flex;
}
.NewsFeedItem_buttonGroup__1_pEt button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeedItem_center__3g2_y {
  justify-content: center;
  align-items: center;
}
.NewsFeedItem_bodyMapDot__1N11G {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NewsFeedItem_good__2VnDO,
.NewsFeedItem_success__2k8wc {
  color: #26d87c;
}
.NewsFeedItem_warning__2ch1t {
  color: #f8c81c;
}
.NewsFeedItem_bad__1kjFE,
.NewsFeedItem_danger__27PJE {
  color: #ee1737;
}
.NewsFeedItem_niceSwitch__3XtH2 {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NewsFeedItem_niceSwitch__3XtH2 + .NewsFeedItem_niceSwitch__3XtH2 {
  margin-top: calc(-1rem + 8px);
}
.NewsFeedItem_switch__L-baO {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NewsFeedItem_switch__L-baO label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NewsFeedItem_switch__L-baO label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NewsFeedItem_switch__L-baO label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NewsFeedItem_switch__L-baO label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NewsFeedItem_tableSortButton__2shgZ) {
  padding: 0 !important;
}
th:has(.NewsFeedItem_tableSortButton__2shgZ) .NewsFeedItem_tableSortButton__2shgZ {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NewsFeedItem_tableSortButton__2shgZ) .NewsFeedItem_tableSortButton__2shgZ::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NewsFeedItem_tableSortButton__2shgZ) .NewsFeedItem_tableSortButton__2shgZ.NewsFeedItem_descending__4ohSm::after {
  content: '\25BC';
}
th:has(.NewsFeedItem_tableSortButton__2shgZ) .NewsFeedItem_tableSortButton__2shgZ.NewsFeedItem_ascending__orFGl::after {
  content: '\25B2';
}
table.NewsFeedItem_tableSort__mJFwI th:has(button) {
  padding: 0 !important;
}
table.NewsFeedItem_tableSort__mJFwI th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NewsFeedItem_printHidden__13Oio {
    display: none !important;
  }
}
.NewsFeedItem_pageHead__3hADa {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NewsFeedItem_pageHead__3hADa::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NewsFeedItem_noArrows__2pLH5::-webkit-outer-spin-button,
input.NewsFeedItem_noArrows__2pLH5::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NewsFeedItem_noArrows__2pLH5[type='number'] {
  -moz-appearance: textfield;
}
div.NewsFeedItem_light__3FQqr {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeedItem_spin__3A5vb {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeedItem_spinCentre__1B5ue {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NewsFeedItem_item__1EwEC {
  display: flex;
  align-items: center;
  transition: border-color 10s ease;
  margin-bottom: 10px;
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
  padding: 10px;
  font-size: 16px;
}
.NewsFeedItem_item__1EwEC:last-child {
  margin-bottom: 30px;
}
.NewsFeedItem_item__1EwEC.NewsFeedItem_unread__1k1cw {
  border-color: #f8c81c;
}
.NewsFeedItem_item__1EwEC img {
  vertical-align: middle;
  margin-right: 15px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
}
.NewsFeedItem_item__1EwEC a {
  font-weight: bold;
  text-decoration: none;
}
.NewsFeedItem_item__1EwEC em {
  color: #fff;
  font-style: normal;
}
.NewsFeedItem_item__1EwEC p {
  flex-grow: 1;
  margin: 0 0 3px !important;
  line-height: 1.2em;
}
.NewsFeedItem_item__1EwEC time {
  color: #8a96a0;
  font-weight: bold;
  font-size: 11px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Avatar_container__Io8r9 {
  padding: 0 30px 30px;
}
.Avatar_containerAll__28jWa {
  padding: 30px;
}
.Avatar_containerTb__3TnAp {
  padding: 30px 0;
}
.Avatar_containerLr__13bM- {
  margin: 0 30px;
}
.Avatar_containerRbl__HLXYD {
  margin: 0 30px 30px;
}
.Avatar_textCenter__25ZwE {
  text-align: center;
}
.Avatar_flex__3OhKb {
  display: flex;
}
.Avatar_well__ZrcXQ {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Avatar_well__ZrcXQ:not(:last-child) {
  margin-bottom: 10px;
}
.Avatar_white__2lvha {
  color: #fff;
}
.Avatar_error__3ASk8 {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Avatar_disabled__1XzWh {
  opacity: 0.5;
  pointer-events: none;
}
.Avatar_graph__flB3E {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Avatar_graphHeader__3OE-y,
.Avatar_graph-header__3sTyz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Avatar_buttonGroup__1lAxJ {
  display: flex;
}
.Avatar_buttonGroup__1lAxJ button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Avatar_center__2kGDV {
  justify-content: center;
  align-items: center;
}
.Avatar_bodyMapDot__3g8U5 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Avatar_good__35aqQ,
.Avatar_success__toGy8 {
  color: #26d87c;
}
.Avatar_warning__kxBLt {
  color: #f8c81c;
}
.Avatar_bad__Ijtzm,
.Avatar_danger__2CJKM {
  color: #ee1737;
}
.Avatar_niceSwitch__38sDx {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Avatar_niceSwitch__38sDx + .Avatar_niceSwitch__38sDx {
  margin-top: calc(-1rem + 8px);
}
.Avatar_switch__1ocK1 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Avatar_switch__1ocK1 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Avatar_switch__1ocK1 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Avatar_switch__1ocK1 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Avatar_switch__1ocK1 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Avatar_tableSortButton__2XI_X) {
  padding: 0 !important;
}
th:has(.Avatar_tableSortButton__2XI_X) .Avatar_tableSortButton__2XI_X {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Avatar_tableSortButton__2XI_X) .Avatar_tableSortButton__2XI_X::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Avatar_tableSortButton__2XI_X) .Avatar_tableSortButton__2XI_X.Avatar_descending__3PU9H::after {
  content: '\25BC';
}
th:has(.Avatar_tableSortButton__2XI_X) .Avatar_tableSortButton__2XI_X.Avatar_ascending__37byx::after {
  content: '\25B2';
}
table.Avatar_tableSort__3u3Vh th:has(button) {
  padding: 0 !important;
}
table.Avatar_tableSort__3u3Vh th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Avatar_printHidden__3b3Zr {
    display: none !important;
  }
}
.Avatar_pageHead__11Dnh {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Avatar_pageHead__11Dnh::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Avatar_noArrows__EIbBi::-webkit-outer-spin-button,
input.Avatar_noArrows__EIbBi::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Avatar_noArrows__EIbBi[type='number'] {
  -moz-appearance: textfield;
}
div.Avatar_light__3tr-c {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Avatar_spin__2kTNH {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Avatar_spinCentre__3n4ap {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Avatar_root__S-GyK.Avatar_small__2Xg6l {
  width: 36px;
  height: 36px;
}
.Avatar_root__S-GyK.Avatar_round__7vdJM {
  border-radius: 50%;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Spinner_container__3EAdX {
  padding: 0 30px 30px;
}
.Spinner_containerAll__3Zv8V {
  padding: 30px;
}
.Spinner_containerTb__3bDSA {
  padding: 30px 0;
}
.Spinner_containerLr__2ZlJl {
  margin: 0 30px;
}
.Spinner_containerRbl__1YzXt {
  margin: 0 30px 30px;
}
.Spinner_textCenter__23Ths {
  text-align: center;
}
.Spinner_flex__37DEB {
  display: flex;
}
.Spinner_well__3xdGj {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Spinner_well__3xdGj:not(:last-child) {
  margin-bottom: 10px;
}
.Spinner_white__1hyh- {
  color: #fff;
}
.Spinner_error__2QHDW {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Spinner_disabled__2mPGb {
  opacity: 0.5;
  pointer-events: none;
}
.Spinner_graph__2lkax {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Spinner_graphHeader__3eOlI,
.Spinner_graph-header__kFdc9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Spinner_buttonGroup__2AkJw {
  display: flex;
}
.Spinner_buttonGroup__2AkJw button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Spinner_center__39WsZ {
  justify-content: center;
  align-items: center;
}
.Spinner_bodyMapDot__24Y4J {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Spinner_good__2hbu_,
.Spinner_success__CBPiO {
  color: #26d87c;
}
.Spinner_warning__YKgir {
  color: #f8c81c;
}
.Spinner_bad__XEZDQ,
.Spinner_danger__dxL4v {
  color: #ee1737;
}
.Spinner_niceSwitch__3eHCv {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Spinner_niceSwitch__3eHCv + .Spinner_niceSwitch__3eHCv {
  margin-top: calc(-1rem + 8px);
}
.Spinner_switch__1wyQn {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Spinner_switch__1wyQn label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Spinner_switch__1wyQn label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Spinner_switch__1wyQn label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Spinner_switch__1wyQn label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Spinner_tableSortButton__1N5po) {
  padding: 0 !important;
}
th:has(.Spinner_tableSortButton__1N5po) .Spinner_tableSortButton__1N5po {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Spinner_tableSortButton__1N5po) .Spinner_tableSortButton__1N5po::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Spinner_tableSortButton__1N5po) .Spinner_tableSortButton__1N5po.Spinner_descending__2SlFG::after {
  content: '\25BC';
}
th:has(.Spinner_tableSortButton__1N5po) .Spinner_tableSortButton__1N5po.Spinner_ascending__2Xqtf::after {
  content: '\25B2';
}
table.Spinner_tableSort__SG5jK th:has(button) {
  padding: 0 !important;
}
table.Spinner_tableSort__SG5jK th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Spinner_printHidden__3HCZ8 {
    display: none !important;
  }
}
.Spinner_pageHead__181l4 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Spinner_pageHead__181l4::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Spinner_noArrows__3QSkt::-webkit-outer-spin-button,
input.Spinner_noArrows__3QSkt::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Spinner_noArrows__3QSkt[type='number'] {
  -moz-appearance: textfield;
}
div.Spinner_light__SOCNi {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Spinner_spin__2YFHg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_spinCentre__3SyuA {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Spinner_spinner__ZnFZc {
  display: inline-block;
  opacity: 0;
  -webkit-animation: Spinner_spin__2YFHg 1s linear infinite;
  animation: Spinner_spin__2YFHg 1s linear infinite;
  transition: opacity 0.15s ease;
  border: 3px solid #8a96a0;
  border-top-color: transparent;
  border-radius: 50%;
  pointer-events: none;
}
.Spinner_centre__wMy6i {
  -webkit-animation: Spinner_spinCentre__3SyuA 1s linear infinite;
  animation: Spinner_spinCentre__3SyuA 1s linear infinite;
}
.Spinner_show__17gsZ {
  opacity: 1;
  transition-delay: 0.75s;
}
.Spinner_small__10tFP {
  width: 22.5px;
  height: 22.5px;
}
.Spinner_medium__2yNXb {
  width: 45px;
  height: 45px;
}
.Spinner_large__2a3GF {
  width: 60px;
  height: 60px;
}
.Spinner_block__1pFZ_ {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
.Spinner_noStretch__JK8Gp {
  margin-top: 30px;
  height: auto;
}
.Spinner_fixed__2lP0d {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorList_container__qicle {
  padding: 0 30px 30px;
}
.ErrorList_containerAll__3mokP {
  padding: 30px;
}
.ErrorList_containerTb__2d7P1 {
  padding: 30px 0;
}
.ErrorList_containerLr__1xyM_ {
  margin: 0 30px;
}
.ErrorList_containerRbl__blCRl {
  margin: 0 30px 30px;
}
.ErrorList_textCenter__3TPWf {
  text-align: center;
}
.ErrorList_flex__27kHX {
  display: flex;
}
.ErrorList_well__2Tdpq {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.ErrorList_well__2Tdpq:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorList_white__2l5xB {
  color: #fff;
}
.ErrorList_error__39nua {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.ErrorList_disabled__1Iblx {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorList_graph__1-V5R {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.ErrorList_graphHeader__3-ZeZ,
.ErrorList_graph-header__17WNu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.ErrorList_buttonGroup__1KkWC {
  display: flex;
}
.ErrorList_buttonGroup__1KkWC button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorList_center__1LBhC {
  justify-content: center;
  align-items: center;
}
.ErrorList_bodyMapDot__uOX3Y {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.ErrorList_good__2tMj3,
.ErrorList_success__1_L2j {
  color: #26d87c;
}
.ErrorList_warning__1rZBk {
  color: #f8c81c;
}
.ErrorList_bad__31FCL,
.ErrorList_danger__3TBxH {
  color: #ee1737;
}
.ErrorList_niceSwitch__2LtVD {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.ErrorList_niceSwitch__2LtVD + .ErrorList_niceSwitch__2LtVD {
  margin-top: calc(-1rem + 8px);
}
.ErrorList_switch__1PXHl {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.ErrorList_switch__1PXHl label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.ErrorList_switch__1PXHl label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.ErrorList_switch__1PXHl label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.ErrorList_switch__1PXHl label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.ErrorList_tableSortButton__2Df1f) {
  padding: 0 !important;
}
th:has(.ErrorList_tableSortButton__2Df1f) .ErrorList_tableSortButton__2Df1f {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.ErrorList_tableSortButton__2Df1f) .ErrorList_tableSortButton__2Df1f::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.ErrorList_tableSortButton__2Df1f) .ErrorList_tableSortButton__2Df1f.ErrorList_descending__1Wqb8::after {
  content: '\25BC';
}
th:has(.ErrorList_tableSortButton__2Df1f) .ErrorList_tableSortButton__2Df1f.ErrorList_ascending__2ANHx::after {
  content: '\25B2';
}
table.ErrorList_tableSort__3sYtC th:has(button) {
  padding: 0 !important;
}
table.ErrorList_tableSort__3sYtC th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .ErrorList_printHidden__1flLT {
    display: none !important;
  }
}
.ErrorList_pageHead__1wJVq {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.ErrorList_pageHead__1wJVq::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.ErrorList_noArrows__3iYWR::-webkit-outer-spin-button,
input.ErrorList_noArrows__3iYWR::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.ErrorList_noArrows__3iYWR[type='number'] {
  -moz-appearance: textfield;
}
div.ErrorList_light__2nKKd {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes ErrorList_spin__3ikyP {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorList_spinCentre__3V6H6 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorList_errorList__1xP2u {
  display: block;
  border: 1px solid #c40e29;
  border-radius: 5px;
  background-color: rgba(238, 23, 55, 0.05);
  padding: 15px;
  color: #ee1737;
  line-height: 1.5em;
  list-style: none;
}
.ErrorList_errorList__1xP2u:not(:last-child) {
  margin-bottom: 30px;
}
.ErrorList_errorList__1xP2u li:not(:last-child) {
  margin-bottom: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Divider_container__2b5sE {
  padding: 0 30px 30px;
}
.Divider_containerAll__1HwuP {
  padding: 30px;
}
.Divider_containerTb__8S272 {
  padding: 30px 0;
}
.Divider_containerLr__39fl5 {
  margin: 0 30px;
}
.Divider_containerRbl__3V4kb {
  margin: 0 30px 30px;
}
.Divider_textCenter__xCGZI {
  text-align: center;
}
.Divider_flex__1tdYb {
  display: flex;
}
.Divider_well__1U1iy {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Divider_well__1U1iy:not(:last-child) {
  margin-bottom: 10px;
}
.Divider_white__3C2LI {
  color: #fff;
}
.Divider_error__1h-wX {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Divider_disabled__194rf {
  opacity: 0.5;
  pointer-events: none;
}
.Divider_graph__3JX0i {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Divider_graphHeader__2EL6h,
.Divider_graph-header__f7Fxw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Divider_buttonGroup__1E8ds {
  display: flex;
}
.Divider_buttonGroup__1E8ds button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Divider_center__2JVE5 {
  justify-content: center;
  align-items: center;
}
.Divider_bodyMapDot__27iyR {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Divider_good__RVo4X,
.Divider_success__tMKHo {
  color: #26d87c;
}
.Divider_warning__F9n5M {
  color: #f8c81c;
}
.Divider_bad__GGYIn,
.Divider_danger__2gYdt {
  color: #ee1737;
}
.Divider_niceSwitch__h07Cl {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Divider_niceSwitch__h07Cl + .Divider_niceSwitch__h07Cl {
  margin-top: calc(-1rem + 8px);
}
.Divider_switch__dVG2Y {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Divider_switch__dVG2Y label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Divider_switch__dVG2Y label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Divider_switch__dVG2Y label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Divider_switch__dVG2Y label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Divider_tableSortButton__2hwCz) {
  padding: 0 !important;
}
th:has(.Divider_tableSortButton__2hwCz) .Divider_tableSortButton__2hwCz {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Divider_tableSortButton__2hwCz) .Divider_tableSortButton__2hwCz::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Divider_tableSortButton__2hwCz) .Divider_tableSortButton__2hwCz.Divider_descending__3hqXl::after {
  content: '\25BC';
}
th:has(.Divider_tableSortButton__2hwCz) .Divider_tableSortButton__2hwCz.Divider_ascending__2pam4::after {
  content: '\25B2';
}
table.Divider_tableSort__xXklV th:has(button) {
  padding: 0 !important;
}
table.Divider_tableSort__xXklV th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Divider_printHidden__pwTbg {
    display: none !important;
  }
}
.Divider_pageHead__2Cy0A {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Divider_pageHead__2Cy0A::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Divider_noArrows__2LQeY::-webkit-outer-spin-button,
input.Divider_noArrows__2LQeY::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Divider_noArrows__2LQeY[type='number'] {
  -moz-appearance: textfield;
}
div.Divider_light__1YLJI {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Divider_spin__n-_oS {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Divider_spinCentre__1ZMKd {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Divider_divider__2onec {
  display: flex;
  align-items: center;
  margin: 30px 0;
  color: #8a96a0;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0;
  text-transform: uppercase;
}
.Divider_divider__2onec:after {
  display: block;
  flex-grow: 1;
  margin-left: 10px;
  background-color: #41494f;
  height: 1px;
  content: '';
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.styles_container__3dp1X {
  padding: 0 30px 30px;
}
.styles_containerAll__3F7k1 {
  padding: 30px;
}
.styles_containerTb__3pJ7q {
  padding: 30px 0;
}
.styles_containerLr__1snzB {
  margin: 0 30px;
}
.styles_containerRbl__2WFtO {
  margin: 0 30px 30px;
}
.styles_textCenter__3KvsM {
  text-align: center;
}
.styles_flex__1U8Ua {
  display: flex;
}
.styles_well__2uOzc {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.styles_well__2uOzc:not(:last-child) {
  margin-bottom: 10px;
}
.styles_white__28F_l {
  color: #fff;
}
.styles_error__3SpEH {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.styles_disabled__3EZYq {
  opacity: 0.5;
  pointer-events: none;
}
.styles_graph__1QEx5 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.styles_graphHeader__2Zekv,
.styles_graph-header__3X99u {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.styles_buttonGroup__G9wGn {
  display: flex;
}
.styles_buttonGroup__G9wGn button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.styles_center__3l0ul {
  justify-content: center;
  align-items: center;
}
.styles_bodyMapDot__3cGrv {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.styles_good__1RuHy,
.styles_success__2Wtz- {
  color: #26d87c;
}
.styles_warning__2reiV {
  color: #f8c81c;
}
.styles_bad__1YFD6,
.styles_danger__3k_Kw {
  color: #ee1737;
}
.styles_niceSwitch__3iQ8c {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.styles_niceSwitch__3iQ8c + .styles_niceSwitch__3iQ8c {
  margin-top: calc(-1rem + 8px);
}
.styles_switch__2szdZ {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.styles_switch__2szdZ label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.styles_switch__2szdZ label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.styles_switch__2szdZ label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.styles_switch__2szdZ label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.styles_tableSortButton__514pP) {
  padding: 0 !important;
}
th:has(.styles_tableSortButton__514pP) .styles_tableSortButton__514pP {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.styles_tableSortButton__514pP) .styles_tableSortButton__514pP::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.styles_tableSortButton__514pP) .styles_tableSortButton__514pP.styles_descending__YDtS8::after {
  content: '\25BC';
}
th:has(.styles_tableSortButton__514pP) .styles_tableSortButton__514pP.styles_ascending__2LYkD::after {
  content: '\25B2';
}
table.styles_tableSort__BYPCJ th:has(button) {
  padding: 0 !important;
}
table.styles_tableSort__BYPCJ th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .styles_printHidden__2_MFl {
    display: none !important;
  }
}
.styles_pageHead__3z7DC {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.styles_pageHead__3z7DC::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.styles_noArrows__1Gq0U::-webkit-outer-spin-button,
input.styles_noArrows__1Gq0U::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.styles_noArrows__1Gq0U[type='number'] {
  -moz-appearance: textfield;
}
div.styles_light__2BF-J {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes styles_spin__uY9-_ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes styles_spinCentre__33F3v {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.styles_switchBtn__2tyqA {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-100px, -50%, 0);
  transform: translate3d(-100px, -50%, 0);
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
}
.styles_switchBtn__2tyqA svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .styles_switchBtn__2tyqA {
    -webkit-transform: translate3d(95px, -175%, 0);
    transform: translate3d(95px, -175%, 0);
  }
}
.styles_overlay__MD1Jz {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  z-index: 25;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
  background-color: rgba(26, 29, 31, 0.8);
  overflow: hidden;
}
.styles_overlayOpen__3GN86 {
  opacity: 1;
}
.styles_overlayClose__2i4Ol {
  opacity: 0;
  transition-delay: 0.1s;
}
.styles_modal__SvUgs {
  opacity: 1;
  margin: 0 auto;
  inset: unset;
  border: none;
  background: none;
  padding: 100px 30px;
  width: 100%;
  max-width: 960px;
  max-height: 100vh;
  overflow: auto;
}
.styles_modal__SvUgs h3 {
  margin-bottom: 30px;
}
.styles_header__2weyt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.styles_header__2weyt input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  padding: 11px 0;
  min-width: 50%;
  color: #fff;
  text-indent: 16px;
}
.styles_header__2weyt input::-webkit-input-placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_header__2weyt input::placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_tabs__1_IXY {
  display: flex;
  position: relative;
  justify-content: stretch;
  align-items: center;
  border-radius: 8px;
  background: #1a1d1f;
  padding: 3px;
}
.styles_tabs__1_IXY::before {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 0;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
  border-radius: 4px;
  background: #8a96a0;
  width: 115px;
  height: calc(100% - 6px);
  content: '';
}
.styles_tabs__1_IXY.styles_flip__rU01D::before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.styles_tabs__1_IXY.styles_flip__rU01D button:first-child {
  color: #8a96a0;
}
.styles_tabs__1_IXY.styles_flip__rU01D button:last-child {
  color: #1a1d1f;
}
.styles_tabs__1_IXY button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  transition: color 0.15s ease;
  cursor: pointer;
  border: none;
  background: none;
  padding: 10px 0;
  width: 115px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
.styles_tabs__1_IXY button:first-child {
  color: #1a1d1f;
}
.styles_orgs__1bGnz {
  grid-gap: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
}
.styles_orgs__1bGnz li button,
.styles_orgs__1bGnz li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background: none;
  width: 100%;
  color: #8a96a0;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
}
.styles_orgs__1bGnz li button span,
.styles_orgs__1bGnz li a span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
  background: #fff;
  width: 100px;
  height: 100px;
  overflow: hidden;
  color: #f8c81c;
  font-weight: bold;
  font-size: 54px;
}
.styles_orgs__1bGnz li button img,
.styles_orgs__1bGnz li a img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.styles_orgs__1bGnz li button svg,
.styles_orgs__1bGnz li a svg {
  vertical-align: middle;
}
.styles_orgs__1bGnz li button small,
.styles_orgs__1bGnz li a small {
  display: block;
  opacity: 0.5;
  margin-top: 5px;
  font-size: 11px;
}
.styles_current__bfuHm {
  opacity: 0.5;
  pointer-events: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NoAccess_container__3xcYE {
  padding: 0 30px 30px;
}
.NoAccess_containerAll__BD9U4 {
  padding: 30px;
}
.NoAccess_containerTb__3aIud {
  padding: 30px 0;
}
.NoAccess_containerLr__1LhxD {
  margin: 0 30px;
}
.NoAccess_containerRbl__2WxTw {
  margin: 0 30px 30px;
}
.NoAccess_textCenter__2G1zw {
  text-align: center;
}
.NoAccess_flex__1PNBg {
  display: flex;
}
.NoAccess_well__4ZN9B {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NoAccess_well__4ZN9B:not(:last-child) {
  margin-bottom: 10px;
}
.NoAccess_white__3TXJO {
  color: #fff;
}
.NoAccess_error__3KgNV {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NoAccess_disabled__1-Kti {
  opacity: 0.5;
  pointer-events: none;
}
.NoAccess_graph__31PGf {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NoAccess_graphHeader__1GorU,
.NoAccess_graph-header__1yIXS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NoAccess_buttonGroup__3nYyZ {
  display: flex;
}
.NoAccess_buttonGroup__3nYyZ button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NoAccess_center__1DaYL {
  justify-content: center;
  align-items: center;
}
.NoAccess_bodyMapDot__1f1wW {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NoAccess_good__1D2jp,
.NoAccess_success__2xfH7 {
  color: #26d87c;
}
.NoAccess_warning__Jtsn5 {
  color: #f8c81c;
}
.NoAccess_bad__2vzMe,
.NoAccess_danger__1pfC_ {
  color: #ee1737;
}
.NoAccess_niceSwitch__1yZhY {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NoAccess_niceSwitch__1yZhY + .NoAccess_niceSwitch__1yZhY {
  margin-top: calc(-1rem + 8px);
}
.NoAccess_switch__b2fG0 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NoAccess_switch__b2fG0 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NoAccess_switch__b2fG0 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NoAccess_switch__b2fG0 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NoAccess_switch__b2fG0 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NoAccess_tableSortButton__gUFBV) {
  padding: 0 !important;
}
th:has(.NoAccess_tableSortButton__gUFBV) .NoAccess_tableSortButton__gUFBV {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NoAccess_tableSortButton__gUFBV) .NoAccess_tableSortButton__gUFBV::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NoAccess_tableSortButton__gUFBV) .NoAccess_tableSortButton__gUFBV.NoAccess_descending__1FxFV::after {
  content: '\25BC';
}
th:has(.NoAccess_tableSortButton__gUFBV) .NoAccess_tableSortButton__gUFBV.NoAccess_ascending__1lOEF::after {
  content: '\25B2';
}
table.NoAccess_tableSort__2XOhk th:has(button) {
  padding: 0 !important;
}
table.NoAccess_tableSort__2XOhk th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NoAccess_printHidden__v2-I3 {
    display: none !important;
  }
}
.NoAccess_pageHead__11FDl {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NoAccess_pageHead__11FDl::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NoAccess_noArrows__1LAlu::-webkit-outer-spin-button,
input.NoAccess_noArrows__1LAlu::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NoAccess_noArrows__1LAlu[type='number'] {
  -moz-appearance: textfield;
}
div.NoAccess_light__1XwFr {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NoAccess_spin__2ZfCN {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NoAccess_spinCentre__1f12g {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NoAccess_wrap__b51Xf {
  padding: 30px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorScreen_container__3C3T6 {
  padding: 0 30px 30px;
}
.ErrorScreen_containerAll__12mym {
  padding: 30px;
}
.ErrorScreen_containerTb__1fHzw {
  padding: 30px 0;
}
.ErrorScreen_containerLr__1GKua {
  margin: 0 30px;
}
.ErrorScreen_containerRbl__3-nzJ {
  margin: 0 30px 30px;
}
.ErrorScreen_textCenter__379mv {
  text-align: center;
}
.ErrorScreen_flex__2SboS {
  display: flex;
}
.ErrorScreen_well__2cGYV {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.ErrorScreen_well__2cGYV:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorScreen_white__3OQLl {
  color: #fff;
}
.ErrorScreen_error__2LXXt {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.ErrorScreen_disabled__2wFGe {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorScreen_graph__3kbfn {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.ErrorScreen_graphHeader__2TYGt,
.ErrorScreen_graph-header__29et4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.ErrorScreen_buttonGroup__2FkJC {
  display: flex;
}
.ErrorScreen_buttonGroup__2FkJC button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorScreen_center__3n0JY {
  justify-content: center;
  align-items: center;
}
.ErrorScreen_bodyMapDot__2FzOu {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.ErrorScreen_good__oe2Tf,
.ErrorScreen_success__oSbbI {
  color: #26d87c;
}
.ErrorScreen_warning__13apw {
  color: #f8c81c;
}
.ErrorScreen_bad__1jjmM,
.ErrorScreen_danger__1pFYs {
  color: #ee1737;
}
.ErrorScreen_niceSwitch__PQ_Wl {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.ErrorScreen_niceSwitch__PQ_Wl + .ErrorScreen_niceSwitch__PQ_Wl {
  margin-top: calc(-1rem + 8px);
}
.ErrorScreen_switch__38CxV {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.ErrorScreen_switch__38CxV label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.ErrorScreen_switch__38CxV label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.ErrorScreen_switch__38CxV label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.ErrorScreen_switch__38CxV label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.ErrorScreen_tableSortButton__PIr1-) {
  padding: 0 !important;
}
th:has(.ErrorScreen_tableSortButton__PIr1-) .ErrorScreen_tableSortButton__PIr1- {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.ErrorScreen_tableSortButton__PIr1-) .ErrorScreen_tableSortButton__PIr1-::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.ErrorScreen_tableSortButton__PIr1-) .ErrorScreen_tableSortButton__PIr1-.ErrorScreen_descending__3I7mf::after {
  content: '\25BC';
}
th:has(.ErrorScreen_tableSortButton__PIr1-) .ErrorScreen_tableSortButton__PIr1-.ErrorScreen_ascending__i2U9R::after {
  content: '\25B2';
}
table.ErrorScreen_tableSort__HI000 th:has(button) {
  padding: 0 !important;
}
table.ErrorScreen_tableSort__HI000 th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .ErrorScreen_printHidden__3aNXq {
    display: none !important;
  }
}
.ErrorScreen_pageHead__4l762 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.ErrorScreen_pageHead__4l762::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.ErrorScreen_noArrows__j2c3a::-webkit-outer-spin-button,
input.ErrorScreen_noArrows__j2c3a::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.ErrorScreen_noArrows__j2c3a[type='number'] {
  -moz-appearance: textfield;
}
div.ErrorScreen_light__3hVQt {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes ErrorScreen_spin__2zDpl {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorScreen_spinCentre__1wCLf {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorScreen_errorScreen__3SqhE {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 30px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.ErrorScreen_errorScreen__3SqhE:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  z-index: -1;
  background-image: url(/static/media/quino.b7cb4007.jpg);
  background-size: cover;
  content: '';
}
.ErrorScreen_errorScreen__3SqhE > div {
  margin: auto;
  max-width: 600px;
}
.ErrorScreen_errorScreen__3SqhE h1 {
  margin-bottom: 30px;
}
.ErrorScreen_errorScreen__3SqhE .ErrorScreen_stack__2zesH {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  font-size: 11px;
  line-height: 1.5em;
}
.ErrorScreen_errorScreen__3SqhE .ErrorScreen_stack__2zesH strong {
  margin-bottom: 10px;
}
.ErrorScreen_errorScreen__3SqhE .ErrorScreen_stack__2zesH code {
  display: block;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  overflow: hidden;
  font-family: 'Fira Code', monospace;
  text-overflow: ellipsis;
}
.ErrorScreen_errorScreen__3SqhE.ErrorScreen_overlay__2RCE- {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 29, 31, 0.9);
  width: auto;
  height: auto;
}
.ErrorScreen_errorScreen__3SqhE.ErrorScreen_overlay__2RCE-:before {
  content: none;
}
.ErrorScreen_footer__2Y4WO {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}
.ErrorScreen_footer__2Y4WO button {
  margin-bottom: 0 !important;
}
.ErrorScreen_footer__2Y4WO button:not(:last-child) {
  margin-right: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Button_container__2eznY {
  padding: 0 30px 30px;
}
.Button_containerAll__34pcM {
  padding: 30px;
}
.Button_containerTb__hHq9J {
  padding: 30px 0;
}
.Button_containerLr__20IlV {
  margin: 0 30px;
}
.Button_containerRbl__3CDgf {
  margin: 0 30px 30px;
}
.Button_textCenter__3oa4k {
  text-align: center;
}
.Button_flex__ivbZA {
  display: flex;
}
.Button_well__2DJju {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Button_well__2DJju:not(:last-child) {
  margin-bottom: 10px;
}
.Button_white__2YdmK {
  color: #fff;
}
.Button_error__HeuEb {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Button_disabled__3qVnN {
  opacity: 0.5;
  pointer-events: none;
}
.Button_graph__25onx {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Button_graphHeader__3JU3r,
.Button_graph-header__17mEQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Button_buttonGroup__1r64g {
  display: flex;
}
.Button_buttonGroup__1r64g button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Button_center__1s-4t {
  justify-content: center;
  align-items: center;
}
.Button_bodyMapDot__3q8DK {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Button_good__-OFkd,
.Button_success__1UKyo {
  color: #26d87c;
}
.Button_warning__1rIdg {
  color: #f8c81c;
}
.Button_bad__3hXvJ,
.Button_danger__XJxHc {
  color: #ee1737;
}
.Button_niceSwitch__1qyqg {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Button_niceSwitch__1qyqg + .Button_niceSwitch__1qyqg {
  margin-top: calc(-1rem + 8px);
}
.Button_switch__191lx {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Button_switch__191lx label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Button_switch__191lx label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Button_switch__191lx label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Button_switch__191lx label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Button_tableSortButton__1nTGc) {
  padding: 0 !important;
}
th:has(.Button_tableSortButton__1nTGc) .Button_tableSortButton__1nTGc {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Button_tableSortButton__1nTGc) .Button_tableSortButton__1nTGc::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Button_tableSortButton__1nTGc) .Button_tableSortButton__1nTGc.Button_descending__1Mmac::after {
  content: '\25BC';
}
th:has(.Button_tableSortButton__1nTGc) .Button_tableSortButton__1nTGc.Button_ascending__3m3G_::after {
  content: '\25B2';
}
table.Button_tableSort__1STWt th:has(button) {
  padding: 0 !important;
}
table.Button_tableSort__1STWt th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Button_printHidden__266zT {
    display: none !important;
  }
}
.Button_pageHead__1qLm4 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Button_pageHead__1qLm4::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Button_noArrows__2hteE::-webkit-outer-spin-button,
input.Button_noArrows__2hteE::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Button_noArrows__2hteE[type='number'] {
  -moz-appearance: textfield;
}
div.Button_light__1cdzp {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Button_spin__1lRJv {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Button_spinCentre__1sNZu {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Button_button__4Cx0H {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.15s ease;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  text-decoration: none;
}
.Button_button__4Cx0H svg:first-child {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;
}
.Button_button__4Cx0H span {
  position: static;
  transition: opacity 0.15s ease;
  color: #222629 !important;
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
}
.Button_button__4Cx0H:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: opacity 0.15s ease;
  border: 3px solid #f8c81c;
  border-top-color: transparent !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: '';
}
.Button_button__4Cx0H:hover {
  opacity: 0.5;
  text-decoration: none;
}
.Button_button__4Cx0H:hover span {
  text-decoration: none;
}
.Button_button__4Cx0H:disabled {
  opacity: 0.25;
  pointer-events: none;
}
.Button_primary__62h25 {
  background-color: #fff;
}
.Button_primary__62h25:after {
  border-color: #222629;
}
.Button_secondary__7iwlF {
  background-color: #8a96a0;
}
.Button_secondary__7iwlF:after {
  border-color: #fff;
}
.Button_tertiary__17mIy {
  background-color: #1a1d1f;
}
.Button_tertiary__17mIy span {
  color: #8a96a0 !important;
}
.Button_quaternary__2HYRH {
  background-color: #41494f;
}
.Button_quaternary__2HYRH:hover {
  opacity: 1 !important;
  background-color: #8a96a0;
}
.Button_brand__2MjpY {
  background-color: #f8c81c;
}
.Button_brand__2MjpY:after {
  border-color: #fff;
}
.Button_dashed__1mG4p {
  border: 1px dashed #41494f;
  border-radius: 8px;
  background: none;
  padding: 7.5px 1rem !important;
}
.Button_dashed__1mG4p span {
  color: #8a96a0 !important;
  font-weight: bold;
  font-size: 0.6875rem;
  text-transform: uppercase;
}
.Button_dashed__1mG4p:after {
  border-color: #41494f;
}
.Button_danger__XJxHc {
  border: none;
  border-radius: 5px;
  background: rgba(238, 23, 55, 0.3);
}
.Button_danger__XJxHc span {
  color: #ee1737 !important;
}
.Button_danger__XJxHc:after {
  border-color: #ee1737;
}
.Button_info__1jH1N {
  border: 1px solid #2196f3;
  border-radius: 5px;
  background: none;
}
.Button_info__1jH1N span {
  color: #2196f3 !important;
}
.Button_info__1jH1N:after {
  border-color: #2196f3;
}
.Button_success__1UKyo {
  border: 1px solid #26d87c;
  border-radius: 5px;
  background: #26d87c;
}
.Button_success__1UKyo span {
  color: #222629 !important;
}
.Button_success__1UKyo:after {
  border-color: #1a1d1f;
}
.Button_danger-small__1uXll {
  border: none;
  background: none;
}
.Button_danger-small__1uXll span {
  color: #ee1737 !important;
  font-size: 12px;
  text-transform: none;
}
.Button_danger-small__1uXll:after {
  border-color: #ee1737;
}
.Button_none__2EqjR {
  border-radius: 0;
  background: none;
}
.Button_text__1YOtw {
  border: none;
  border-radius: 0;
  background: none;
  padding: 0 !important;
  color: #fff;
}
.Button_text__1YOtw span {
  color: currentColor !important;
  font-weight: normal;
  text-decoration: underline !important;
  text-transform: none;
}
.Button_outline__2o5lz {
  border: 1px solid #41494f;
  background: none;
}
.Button_outline__2o5lz span {
  color: #8a96a0 !important;
  font-weight: normal;
}
.Button_small__3MSk- {
  padding: 5px 10px 4px;
}
.Button_small__3MSk-:after {
  width: 8px;
  height: 8px;
}
.Button_small__3MSk-.Button_danger__XJxHc {
  padding: 4px 10px 3px;
}
.Button_small__3MSk- span {
  font-size: 13px;
}
.Button_large__Uzb28 {
  padding: 11px 30px;
}
.Button_large__Uzb28.Button_danger__XJxHc {
  padding: 9px 15px;
}
.Button_huge__1bA4Y {
  padding: 20px;
  width: 100%;
}
.Button_huge__1bA4Y:after {
  width: 16px;
  height: 16px;
}
.Button_huge__1bA4Y.Button_danger__XJxHc {
  padding: 19px 20px;
}
.Button_round__1pNJx {
  border-radius: 20px;
  padding: 0.55rem 1.75rem;
}
.Button_wide__1foJz {
  width: 100%;
}
.Button_busy__2UKTT {
  tab-index: 0;
  pointer-events: none;
}
.Button_busy__2UKTT span {
  opacity: 0;
}
.Button_busy__2UKTT:after {
  opacity: 1;
  -webkit-animation: Button_spinCentre__1sNZu 1s linear infinite;
  animation: Button_spinCentre__1sNZu 1s linear infinite;
}

