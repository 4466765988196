/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '▼';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '▲';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.button {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.15s ease;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  text-decoration: none;
}
.button svg:first-child {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;
}
.button span {
  position: static;
  transition: opacity 0.15s ease;
  color: #222629 !important;
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
}
.button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: opacity 0.15s ease;
  border: 3px solid #f8c81c;
  border-top-color: transparent !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: '';
}
.button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.button:hover span {
  text-decoration: none;
}
.button:disabled {
  opacity: 0.25;
  pointer-events: none;
}
.primary {
  background-color: #fff;
}
.primary:after {
  border-color: #222629;
}
.secondary {
  background-color: #8a96a0;
}
.secondary:after {
  border-color: #fff;
}
.tertiary {
  background-color: #1a1d1f;
}
.tertiary span {
  color: #8a96a0 !important;
}
.quaternary {
  background-color: #41494f;
}
.quaternary:hover {
  opacity: 1 !important;
  background-color: #8a96a0;
}
.brand {
  background-color: #f8c81c;
}
.brand:after {
  border-color: #fff;
}
.dashed {
  border: 1px dashed #41494f;
  border-radius: 8px;
  background: none;
  padding: 7.5px 1rem !important;
}
.dashed span {
  color: #8a96a0 !important;
  font-weight: bold;
  font-size: 0.6875rem;
  text-transform: uppercase;
}
.dashed:after {
  border-color: #41494f;
}
.danger {
  border: none;
  border-radius: 5px;
  background: rgba(238, 23, 55, 0.3);
}
.danger span {
  color: #ee1737 !important;
}
.danger:after {
  border-color: #ee1737;
}
.info {
  border: 1px solid #2196f3;
  border-radius: 5px;
  background: none;
}
.info span {
  color: #2196f3 !important;
}
.info:after {
  border-color: #2196f3;
}
.success {
  border: 1px solid #26d87c;
  border-radius: 5px;
  background: #26d87c;
}
.success span {
  color: #222629 !important;
}
.success:after {
  border-color: #1a1d1f;
}
.danger-small {
  border: none;
  background: none;
}
.danger-small span {
  color: #ee1737 !important;
  font-size: 12px;
  text-transform: none;
}
.danger-small:after {
  border-color: #ee1737;
}
.none {
  border-radius: 0;
  background: none;
}
.text {
  border: none;
  border-radius: 0;
  background: none;
  padding: 0 !important;
  color: #fff;
}
.text span {
  color: currentColor !important;
  font-weight: normal;
  text-decoration: underline !important;
  text-transform: none;
}
.outline {
  border: 1px solid #41494f;
  background: none;
}
.outline span {
  color: #8a96a0 !important;
  font-weight: normal;
}
.small {
  padding: 5px 10px 4px;
}
.small:after {
  width: 8px;
  height: 8px;
}
.small.danger {
  padding: 4px 10px 3px;
}
.small span {
  font-size: 13px;
}
.large {
  padding: 11px 30px;
}
.large.danger {
  padding: 9px 15px;
}
.huge {
  padding: 20px;
  width: 100%;
}
.huge:after {
  width: 16px;
  height: 16px;
}
.huge.danger {
  padding: 19px 20px;
}
.round {
  border-radius: 20px;
  padding: 0.55rem 1.75rem;
}
.wide {
  width: 100%;
}
.busy {
  tab-index: 0;
  pointer-events: none;
}
.busy span {
  opacity: 0;
}
.busy:after {
  opacity: 1;
  -webkit-animation: spinCentre 1s linear infinite;
  animation: spinCentre 1s linear infinite;
}
